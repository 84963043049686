import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Country } from 'country-state-city';

import { useDispatch, useSelector } from 'react-redux';
import { kycStatuses } from '../../../helpers/constants';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import Alert from '../../Common/Alert';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { KycCompletedModalContent } from '../../Common/KycCompletedModalContent';

import { idTypeOptions } from '../../../helpers/constants';
import KycChangeReasonModal from '../../KYC/Components/KycChangeReasonModal';
import { requestKYCupdate } from '../../KYC/_redux/kycCrud';
import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import { sendFormDataToBackend } from '../_redux/individualKYCAction';
import { Confirmation } from '../components/ComfirmUpdateReason';
import { DisplayDocuments } from '../components/DisplayDocuments';
import { DocumentsWrapper } from '../components/DocumentsWrapper';
import { SummaryPageRow } from '../components/SummaryPageRow';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import Back from '../../Common/BackArrow';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { TopBottomSpacing } from '../components/TopBottomSpacing';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { InvestorInformationSummaryTitle } from '../../Business/components/InvestorInformationSummaryTitle';

const Summary = React.memo(props => {
  let history = useHistory();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [show, setShow] = useState(false);

  const [openReasonModal, setOpenReasonModal] = useState(false);
  const [reason, setReason] = useState('');
  const [displayConfirmPage, setDisplayConfirmPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendingReason, setSendingReason] = useState(false);
  const kycDataFromState = useSelector(state => state.kyc);

  const replaceString = value => {
    return value?.replace(/_/g, ' ')?.replace(/\b\w/g, c => c.toUpperCase());
  };

  const {
    handleStepChange,
    id: stepperId,
    updating,
    submittedInvestorSummaryPage,
  } = props;
  const { investor, user } = useSelector(state => state?.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    handleStepChange && handleStepChange({ next: stepperId });
  }, [handleStepChange, stepperId]);

  const sendKYCupdateRequest = async () => {
    setSendingReason(true);
    try {
      setAlert(null);
      const res = await requestKYCupdate(reason);

      if (res.status === 201) {
        setSendingReason(false);
        setDisplayConfirmPage(true);
        setOpenReasonModal(false);
      }
    } catch (error) {
      setSendingReason(false);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
    }
  };

  const handleHide = () => {
    localStorage.removeItem('country');
    localStorage.removeItem('idtype');
    localStorage.removeItem('kycDetails');
    localStorage.removeItem('kyc');
    dispatch(kycActions?.cleanKycState());
    setShow(false);
    history.push(investor?.kycExtractionMatch ? '/plans' : '/');
  };

  const handleSubmit = async () => {
    try {
      // Send formData to the backend
      setLoading(true);
      delete kycDataFromState?.facePhoto;

      const response = await sendFormDataToBackend(kycDataFromState);

      if (response.status === 200) {
        setShow(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const kycEntryPointUrl = localStorage.getItem('kycEntryPointUrl');

  return (
    <>
      {displayConfirmPage ? (
        <Confirmation />
      ) : (
        <>
          <Back
            title={`${capitalizeTheFirstLetterOfEachWord(
              investor?.investorType,
            )} Information`}
            onClick={() => {
              submittedInvestorSummaryPage &&
                localStorage.setItem('kycEntryPointUrl', kycEntryPointUrl);
              history.goBack();
            }}
          />

          <KycPageContentWrapper>
            <div>{alert?.alertMessage ? <Alert alert={alert} /> : null}</div>

            <div className="d-flex justify-content-between align-items-start buttons-gap flex-wrap mt-5">
              <InvestorInformationSummaryTitle
                firstName={user?.firstName}
                lastName={user?.lastName}
                investorType={investor?.investorType}
              />

              {investor?.kycStatus === kycStatuses.COMPLETED ? (
                <ConfirmButton
                  buttonText={'UPDATE INVESTOR INFO'}
                  handleOnClick={() => setOpenReasonModal(true)}
                  loading={loading}
                  disable={investor?.hasRequestedToEditKyc}
                />
              ) : null}
            </div>

            <TopBottomSpacing />

            <div className="mt-8">
              {user?.firstName ? (
                <SummaryPageRow label={'First Name'} value={user?.firstName} />
              ) : null}

              {user?.lastName ? (
                <SummaryPageRow label={'Last Name'} value={user?.lastName} />
              ) : null}

              {investor?.gender || kycDataFromState?.gender ? (
                <SummaryPageRow
                  label={'Gender'}
                  value={capitalizeTheFirstLetterOfEachWord(
                    kycDataFromState?.gender
                      ? kycDataFromState?.gender
                      : investor?.gender,
                  )}
                />
              ) : null}

              {user?.email ? (
                <SummaryPageRow label={'Email Address'} value={user?.email} />
              ) : null}

              {investor?.phoneNumber ? (
                <SummaryPageRow
                  label={'Phone Number'}
                  value={investor?.phoneNumber}
                />
              ) : null}

              {investor?.dateOfBirth || kycDataFromState?.dateOfBirth ? (
                <SummaryPageRow
                  label={'D.O.B'}
                  value={
                    kycDataFromState?.dateOfBirth
                      ? kycDataFromState?.dateOfBirth
                      : investor?.dateOfBirth
                  }
                />
              ) : null}
            </div>

            <TopBottomSpacing />

            <div className="mt-3">
              {investor?.city || kycDataFromState?.city ? (
                <SummaryPageRow
                  label={'City'}
                  value={`${
                    kycDataFromState?.city
                      ? kycDataFromState?.city
                      : investor?.city
                  }, ${
                    Country.getCountryByCode(
                      kycDataFromState?.country
                        ? kycDataFromState?.country
                        : investor.country,
                    )?.name
                  }`}
                />
              ) : null}

              {investor?.residentialAddress ||
              kycDataFromState?.residentialAddress ? (
                <SummaryPageRow
                  label={'Residential Address'}
                  value={
                    kycDataFromState?.residentialAddress
                      ? kycDataFromState?.residentialAddress
                      : investor.residentialAddress
                  }
                />
              ) : null}

              {investor?.postalCode || kycDataFromState?.postalCode ? (
                <SummaryPageRow
                  label={'Postal Code'}
                  value={
                    kycDataFromState?.postalCode
                      ? kycDataFromState?.postalCode
                      : investor.postalCode
                  }
                />
              ) : null}
            </div>

            <TopBottomSpacing />

            <div className="mt-3">
              {investor?.employmentStatus ||
              kycDataFromState?.employmentStatus ? (
                <SummaryPageRow
                  label={'Employment Status'}
                  value={replaceString(
                    kycDataFromState?.employmentStatus
                      ? kycDataFromState?.employmentStatus
                      : investor.employmentStatus,
                  )}
                />
              ) : null}

              {investor?.sourceOfFunds || kycDataFromState?.sourceOfFunds ? (
                <SummaryPageRow
                  label={'Source of Funds'}
                  value={capitalizeTheFirstLetterOfEachWord(
                    replaceString(
                      kycDataFromState?.sourceOfFunds
                        ? kycDataFromState?.sourceOfFunds
                        : investor.sourceOfFunds,
                    ),
                  )?.replace('Or', 'or')}
                />
              ) : null}

              {investor?.nextOfKinNames || kycDataFromState?.nextOfKinNames ? (
                <SummaryPageRow
                  label={'Next of Kin Full Name'}
                  value={
                    kycDataFromState?.nextOfKinNames
                      ? kycDataFromState?.nextOfKinNames
                      : investor.nextOfKinNames
                  }
                />
              ) : null}

              {investor?.nextOfKinContact ||
              kycDataFromState?.nextOfKinContact ? (
                <SummaryPageRow
                  label={'Next of Kin Contact'}
                  value={
                    kycDataFromState?.nextOfKinContact
                      ? kycDataFromState?.nextOfKinContact
                      : investor.nextOfKinContact
                  }
                />
              ) : null}

              {investor?.nextOfKinEmail || kycDataFromState?.nextOfKinEmail ? (
                <SummaryPageRow
                  label={'Next of Kin Email'}
                  value={
                    kycDataFromState?.nextOfKinEmail
                      ? kycDataFromState?.nextOfKinEmail
                      : investor.nextOfKinEmail
                  }
                />
              ) : null}
            </div>

            <TopBottomSpacing />
            <div className="mt-3">
              {investor?.identificationType || kycDataFromState?.idType ? (
                <SummaryPageRow
                  label={'ID Type'}
                  value={
                    idTypeOptions[
                      kycDataFromState?.idType?.toUpperCase() ||
                        investor.identificationType
                    ]
                  }
                />
              ) : null}

              {investor?.identificationType || kycDataFromState?.idType ? (
                <SummaryPageRow
                  label={`${
                    idTypeOptions[
                      investor?.identificationType?.toUpperCase() ||
                        kycDataFromState?.idType?.toUpperCase()
                    ]
                  } Country`}
                  value={
                    Country.getCountryByCode(
                      kycDataFromState?.identificationCountry
                        ? kycDataFromState?.identificationCountry
                        : investor.identificationCountry,
                    )?.name
                  }
                />
              ) : null}

              {investor?.identificationNumber ||
              kycDataFromState?.identificationNumber ? (
                <SummaryPageRow
                  label={'Identification Number'}
                  value={
                    kycDataFromState?.identificationNumber
                      ? kycDataFromState?.identificationNumber
                      : investor.identificationNumber
                  }
                />
              ) : null}
            </div>

            <TopBottomSpacing />

            <div className="mt-3">
              {investor?.taxPayerNumber || kycDataFromState?.taxPayerNumber ? (
                <SummaryPageRow
                  label={'Tax Payer Number'}
                  value={
                    kycDataFromState?.taxPayerNumber
                      ? kycDataFromState?.taxPayerNumber
                      : investor.taxPayerNumber
                  }
                />
              ) : null}
            </div>

            {investor?.kycLevel === 2 ? (
              <div className="gray-line mt-3"></div>
            ) : null}

            <div className="font-weight-500 mt-15">
              <div className="text-blue text-14 font-weight-600">
                Uploaded Documentation
              </div>
              <div className="text-11">
                The following are documents submitted by investor
              </div>
            </div>

            <TopBottomSpacing />

            <DocumentsWrapper>
              <DisplayDocuments
                file={investor?.identificationFrontSide}
                label={idTypeOptions[investor?.identificationType]}
                setAlert={setAlert}
              />

              <DisplayDocuments
                file={investor?.facePhoto}
                label={'Photo'}
                setAlert={setAlert}
              />
            </DocumentsWrapper>

            <DocumentsWrapper>
              <DisplayDocuments
                file={investor?.taxPinCertificate}
                label={'Tax Pin Certificate'}
                setAlert={setAlert}
              />

              <DisplayDocuments
                file={investor?.proofOfAddress}
                label={'Proof of Address'}
                setAlert={setAlert}
              />
            </DocumentsWrapper>

            {investor?.kycStatus === kycStatuses.PENDING ||
            investor?.kycStatus === kycStatuses.IN_EDIT ||
            investor?.kycStatus === kycStatuses.REJECTED ||
            (investor?.kycStatus === kycStatuses.SUBMITTED && !updating) ? (
              <ButtonsContainer justifyContent="justify-content-between">
                <CancelButton
                  buttonText="Previous"
                  handleOnClick={() => history.goBack()}
                  twinButton
                />

                <ConfirmButton
                  buttonText={updating ? 'UPDATE' : 'COMPLETE'}
                  handleOnClick={() =>
                    updating
                      ? history.push(
                          '/manage-account/investor-information/details',
                        )
                      : handleSubmit()
                  }
                  loading={loading}
                  twinButton
                />
              </ButtonsContainer>
            ) : null}
          </KycPageContentWrapper>
        </>
      )}

      <KycChangeReasonModal
        openReasonModal={openReasonModal}
        setOpenReasonModal={setOpenReasonModal}
        reason={reason}
        setReason={setReason}
        sendKYCupdateRequest={sendKYCupdateRequest}
        loading={sendingReason}
      />

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<KycCompletedModalContent />}
        handleButtonClick={handleHide}
        btnText={investor?.kycExtractionMatch ? 'INVEST NOW' : 'OK'}
        moreClasses={'bg-white'}
      />
    </>
  );
});

export default Summary;
